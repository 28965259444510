const formatPrice = (value, currencyCode, options) => {
  const {
    locale,
    removeTrailingZeros
  } = options || {};

  let price = value;

  if (typeof value === 'string' || (typeof value !== 'number')) price = parseFloat(value);

  if (typeof price === 'number') {
    return price.toLocaleString(locale, {
      currency: currencyCode || 'EUR',
      maximumFractionDigits: 2,
      minimumFractionDigits: removeTrailingZeros ? 0 : 2,
      style: 'currency'
    });
  }

  return '–';
};

export default formatPrice;
