import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Store
import { useSelector } from 'react-redux';
import { selectCurrentRestaurant } from 'store/slices/restaurantsSlice';

// Utils
import bemify from 'utils/bemify';
import formatPrice from 'utils/format-price';

// Hooks
import useAllergens from 'hooks/use-allergens';

// Components
import Icon from 'components/icon';
import Image from 'components/image';
import Tooltip from 'components/tooltip';

// Styles
import stylesModule from './styles.module.scss';


const styles = bemify(stylesModule);

const ProductCard = ({
  className,
  onClick,
  paddingSize,
  priceSize,
  productDetails,
  restaurantCurrency,
  titleSize
}) => {
  const allAllergens = useAllergens();

  const [formattedPrice, setFormattedPrice] = useState(null);

  // Selectors
  const currentRestaurant = useSelector(selectCurrentRestaurant);

  const classNames = `${styles.productCard}${
    onClick ? ` ${styles.productCard_isInteractive}` : ''}${
    paddingSize === 'md' ? ` ${styles.productCard_paddingSizeMedium}` : ''}${
    className ? ` ${className}` : ''}`;

  const priceClassNames = `${styles.productCard__price}${
    priceSize === 's' ? ` ${styles.productCard__price_small}` : ''}`;

  const titleClassNames = `${styles.productCard__title}${
    titleSize === 's' ? ` ${styles.productCard__title_small}` : ''}`;

  const {
    allergens,
    description,
    featured,
    imageUrl,
    peopleServeNumber,
    price,
    title
  } = productDetails || {};

  const hasAllergens = Array.isArray(allergens) && !!allergens.length;

  const handleCardKeyDown = (event) => {
    // Perform action only if spacebar or enter is pressed
    if (event.keyCode === 13 || event.keyCode === 32) {
      event.preventDefault();

      onClick();
    }
  };

  useEffect(() => {
    setFormattedPrice(formatPrice(price, currentRestaurant?.currency || restaurantCurrency));
  }, [currentRestaurant, price, restaurantCurrency]);

  return (
    <div
      className={classNames}
      role={onClick ? 'button' : 'div'}
      tabIndex={onClick ? 0 : -1}
      onClick={onClick}
      onKeyDown={handleCardKeyDown}
    >
      <div className={styles.productCard__info}>
        <div>
          <div className={titleClassNames}>
            {title}
          </div>
          {!!description && (
            <div className={styles.productCard__description}>
              {description}
            </div>
          )}
        </div>
        <div className={styles.productCard__priceAndAllergens}>
          {hasAllergens && (
            <div className={styles.productCard__allergensWrapper}>
              {allergens.map((entry, index) => {
                const productAllergenDetails = allAllergens.find(allergen => allergen.id === entry);

                if (!productAllergenDetails) return null;

                return (
                  <Tooltip
                    key={index}
                    text={productAllergenDetails.label}
                    trigger={(
                      <div>
                        <Icon
                          className={styles.productCard__allergenIcon}
                          name={productAllergenDetails.icon}
                        />
                      </div>
                    )}
                  />
                );
              })}
            </div>
          )}
          <div className={priceClassNames}>
            {formattedPrice}
          </div>
        </div>
      </div>
      {!!imageUrl && (
        <div className={styles.productCard__imageWrapper}>
          {(!!featured || !!peopleServeNumber) && (
            <div className={styles.productCard__icons}>
              {!!peopleServeNumber && (
                <div className={styles.productCard__iconWrapper}>
                  <Icon
                    className={styles.productCard__icon}
                    name="user"
                  />
                  <div className={styles.productCard__peopleServeNumber}>
                    {peopleServeNumber}
                  </div>
                </div>
              )}
              {!!featured && (
                <div className={styles.productCard__iconWrapper}>
                  <Icon
                    className={styles.productCard__featuredIcon}
                    name="star"
                  />
                </div>
              )}
            </div>
          )}
          <Image
            alt={title}
            layout="fill"
            objectFit="cover"
            src={imageUrl}
          />
        </div>
      )}
    </div>
  );
};

ProductCard.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  paddingSize: PropTypes.oneOf(['default', 'md']),
  priceSize: PropTypes.oneOf(['default', 's']),
  productDetails: PropTypes.shape({
    allergens: PropTypes.arrayOf(PropTypes.string),
    description: PropTypes.string,
    featured: PropTypes.bool,
    imageUrl: PropTypes.string,
    ingredients: PropTypes.string,
    peopleServeNumber: PropTypes.number,
    price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    title: PropTypes.string.isRequired
  }),
  restaurantCurrency: PropTypes.string,
  titleSize: PropTypes.oneOf(['default', 's'])
};

ProductCard.defaultProps = {
  className: null,
  onClick: null,
  paddingSize: 'default',
  priceSize: 'default',
  productDetails: null,
  restaurantCurrency: null,
  titleSize: 'default'
};

export default ProductCard;
