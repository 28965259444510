import useTranslationWithFallback from './use-translation-with-fallback';


const useAllergens = () => {
  const { t } = useTranslationWithFallback('common');

  const allergens = [
    {
      checked: false,
      icon: 'vegetarian-diet',
      id: 'vegetarian-diet',
      label: t('allergens.vegetarian')
    },
    {
      checked: false,
      icon: 'vegan-diet',
      id: 'vegan-diet',
      label: t('allergens.vegan')
    },
    {
      checked: false,
      icon: 'gluten-free-diet',
      id: 'gluten-free-diet',
      label: t('allergens.gluten_free')
    },
    {
      checked: false,
      icon: 'lactose-intolerance',
      id: 'lactose-intolerance',
      label: t('allergens.lactose_intolerance')
    },
    {
      checked: false,
      icon: 'kosher',
      id: 'kosher',
      label: t('allergens.kosher')
    },
    {
      checked: false,
      icon: 'low-calories',
      id: 'low-calories',
      label: t('allergens.low_calories')
    },
    {
      checked: false,
      icon: 'diabetes',
      id: 'diabetes',
      label: t('allergens.diabetes')
    }
  ];

  return allergens;
};

export default useAllergens;
