import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as RadixTooltip from '@radix-ui/react-tooltip';
import { useDebouncedCallback } from 'use-debounce';

// Utils
import bemify from 'utils/bemify';

// Components
import Icon from 'components/icon';

// Styles
import stylesModule from './styles.module.scss';


const styles = bemify(stylesModule);

const Tooltip = ({ icon, text, trigger }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [open, setOpen] = useState(false);

  const handleTriggerClick = (event) => {
    // Prevent click event from bubbling up to parent elements when on mobile.
    // On desktop, the tooltip opens on hover, so we don't need to stop propagation.
    if (isMobile) event.stopPropagation();
  };

  const handleResize = useDebouncedCallback(() => {
    if (window.innerWidth > 768 && isMobile) {
      setIsMobile(false);
    } else if (window.innerWidth <= 768 && !isMobile) {
      setIsMobile(true);
    }
  }, 300);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      handleResize();

      window.addEventListener('resize', handleResize, { passive: true });
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return (
    <RadixTooltip.Provider delayDuration={0}>
      <RadixTooltip.Root open={open}>
        <RadixTooltip.Trigger
          asChild
          className="d-flex"
          onBlur={() => setOpen(false)}
          onClick={handleTriggerClick}
          onFocus={() => setOpen(true)}
          onMouseEnter={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
        >
          {trigger}
        </RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <RadixTooltip.Content
            className={styles.tooltip__contentWrapper}
            side="bottom-center"
            sideOffset={5}
          >
            <RadixTooltip.Arrow className={styles.tooltip__arrow} />
            <div className={styles.tooltip__content}>
              {!!icon && (
                <Icon
                  className={styles.tooltip__icon}
                  name={icon}
                />
              )}
              <div className={styles.tooltip__text}>
                {text}
              </div>
            </div>
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
};

Tooltip.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string.isRequired,
  trigger: PropTypes.element.isRequired
};

Tooltip.defaultProps = {
  icon: null
};

export default Tooltip;
