import React from 'react';
import PropTypes from 'prop-types';
import useTranslationWithFallback from 'hooks/use-translation-with-fallback';

// Store
import { useSelector } from 'react-redux';
import { selectCurrentRestaurant } from 'store/slices/restaurantsSlice';

// Utils
import bemify from 'utils/bemify';
import formatPrice from 'utils/format-price';

// Hooks
import useAllergens from 'hooks/use-allergens';

// Components
import Icon from 'components/icon';
import Image from 'components/image';
import Modal from 'components/modal';
import Tooltip from 'components/tooltip';

// Styles
import stylesModule from './styles.module.scss';


const styles = bemify(stylesModule);

const ProductModal = ({
  className,
  onClose,
  productDetails,
  restaurantCurrency,
  show
}) => {
  const { t } = useTranslationWithFallback('common');

  const allAllergens = useAllergens();

  // Selectors
  const currentRestaurant = useSelector(selectCurrentRestaurant);

  const description = productDetails?.description;
  const hasAllergens = Array.isArray(productDetails?.allergens) && !!productDetails.allergens.length;
  const imageUrl = productDetails?.imageUrl;
  const ingredients = productDetails?.ingredients;
  const price = productDetails?.price;
  const title = productDetails?.title;

  const handleCloseButtonKeyDown = (event) => {
    if (event.key === 'Enter') onClose();
  };

  const modalHeader = (
    <div className={styles.productModal__header}>
      {!!imageUrl && (
        <>
          <div className={styles.productModal__imageWrapper}>
            <Image
              alt={title}
              layout="fill"
              objectFit="cover"
              objectPosition="center"
              src={imageUrl}
            />
          </div>
          <div
            className={styles.productModal__closeButton}
            role="button"
            tabIndex={0}
            onClick={onClose}
            onKeyDown={handleCloseButtonKeyDown}
          >
            <Icon name="close" />
          </div>
        </>
      )}
      <h6 className={imageUrl ? styles.productModal__title_withMarginTop : styles.productModal__title_withMarginRight}>
        {title}
      </h6>
      {description && (
        <div className={styles.productModal__description}>
          {description}
        </div>
      )}
    </div>
  );

  return (
    <Modal
      className={`${styles.productModal}${className ? ` ${className}` : ''}`}
      customHeader={modalHeader}
      mobilePosition="bottom"
      noCloseIcon={!!imageUrl}
      show={show}
      size="s"
      onClose={onClose}
    >
      <>
        <div className={styles.productModal__priceAndAllergens}>
          <div className={styles.productModal__price}>
            {formatPrice(price, currentRestaurant?.currency || restaurantCurrency)}
          </div>
          {hasAllergens && (
            <div className={styles.productModal__allergensWrapper}>
              {productDetails.allergens.map((entry, index) => {
                const productAllergenDetails = allAllergens.find(allergen => allergen.id === entry);

                if (!productAllergenDetails) return null;

                return (
                  <Tooltip
                    key={index}
                    text={productAllergenDetails.label}
                    trigger={(
                      <div>
                        <Icon
                          className={styles.productModal__allergenIcon}
                          name={productAllergenDetails.icon}
                        />
                      </div>
                    )}
                  />
                );
              })}
            </div>
          )}
        </div>
        {!!ingredients && (
          <>
            <hr className={styles.productModal__dividerLine} />
            <div className={styles.productModal__ingredientsTitle}>
              {t('product_card_modal.ingredients')}
            </div>
            <div className={`${styles.productModal__ingredients} ${styles.productModal__ingredients_noMarginTop}`}>
              {ingredients}
            </div>
          </>
        )}
      </>
    </Modal>
  );
};

ProductModal.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  productDetails: PropTypes.shape({
    allergens: PropTypes.arrayOf(PropTypes.string),
    description: PropTypes.string,
    imageUrl: PropTypes.string,
    ingredients: PropTypes.string,
    price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    title: PropTypes.string.isRequired
  }),
  restaurantCurrency: PropTypes.string,
  show: PropTypes.bool
};

ProductModal.defaultProps = {
  className: null,
  onClose: () => {},
  productDetails: null,
  restaurantCurrency: null,
  show: false
};

export default ProductModal;
